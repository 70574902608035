<template>
  <div>
    <!-- ArtworkTabsコンポーネントを利用し、各種のデータを渡しています -->
    <artwork-tabs
      v-if="dataFetched"
      :discord-button="true"
      :twitter-items="twitterItems"
      :discord-items="discordItems"
      :youtube-items="youtubeItems"
      :popular-items="popularItems"
      :over1000-likes-items="over1000LikesItems"
      :tabid="tabid"
      :show-button="true"
    />
  </div>
</template>

<script>
  import axios from 'axios';
  import ArtworkTabs from '../components/ArtworkTabs.vue';

  export default {
    metaInfo: {
      title: 'Artwork',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },
    components: {
      ArtworkTabs,
    },
    data() {
      return {
        // 必要なデータのみを定義
        twitterItems: [],
        discordItems: [],
        youtubeItems: [],
        popularItems: [],
        over1000LikesItems: [],
        dataFetched: false, // データの取得が完了したかどうかを示すフラグ
        tabid: null, // デフォルトのタブを選択します。ここを変えることでデフォルトタブを変更できます。
      };
    },
    watch: {
      // '$route.params'の変更を監視しています
      '$route.params': {
        immediate: true,
        handler(newParams) {
          this.tabid = Number(newParams.tabid) || 0;
        },
      },
    },
    created: async function () {
      try {
        // 各サービスのデータを取得します
        await Promise.all([
          this.fetchData('twitter'),
          this.fetchData('discord'),
          this.fetchData('youtube'),
          this.fetchData('popular'),
          this.fetchData('over1000Likes'),
        ]);
        this.dataFetched = true; // データの取得が完了したのでフラグをtrueに設定
      } catch (e) {
        console.error('データの取得に失敗しました:', e);
      }
    },
    methods: {
      // 各サービスのデータを取得するメソッドを共通化
      async fetchData(service) {
        try {
          let url;
          // serviceが"popular"の場合の特別な処理
          if (service === 'popular') {
            url =
              '../../../PopularFF14HousingTweets/PopularFF14HousingTweets.json';
          } else if (service === 'over1000Likes') {
            url =
              '../../../FF14HousingTweetWithOver1000Likes/FF14HousingTweetWithOver1000Likes.json';
          } else {
            url = `../../../artwork/${service}/user_${this.$route.params.id}_${service}.json`;
          }

          const response = await axios.get(
            `${url}?timestamp=${new Date().getTime()}`
          );
          console.log(response);

          // timestampを基に降順に並び替え
          this[`${service}Items`] = response.data.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
        } catch (e) {
          if (e.response && e.response.status === 404) {
            console.log(`${service}のデータが存在しません。`);
            this[`${service}Items`] = [];
          } else {
            // 404エラー以外のエラーをキャッチ
            throw e;
          }
        }
      },
    },
  };
</script>
